import '../styles/main.scss';

import initiator from './locales';
import { getScript } from './utils';

(function(global) {

  const LangArr = [
    { key: 'en', title: 'English' },
    { key: 'jp', title: '日本語', disabled: true, },
    { key: 'kr', title: '한국어', disabled: true, },
    { key: 'pt', title: 'Português', disabled: true, },
    { key: 'fr', title: 'Français', disabled: true, },
    { key: 'ru', title: 'Pусский', disabled: true, },
    { key: 'es', title: 'Español', disabled: true, },
    { key: 'vi', title: 'Tiếng Việt', disabled: true, },
    { key: 'id', title: 'Bahasa id', disabled: true, },
    { key: 'th', title: 'ไทย', disabled: true, },
    { key: 'tw', title: '繁體中文' },
    { key: 'zh', title: '简体中文' },
  ];

  const IndexLocationMap = {
    zh: 'https://mumu.163.com/',
    tw: 'https://www.mumuplayer.com/tw/',
    en: 'https://www.mumuplayer.com/',
    jp: 'https://www.mumuplayer.com/jp/',
    kr: 'https://www.mumuplayer.com/kr/',
    pt: 'https://www.mumuplayer.com/pt/',
    fr: 'https://www.mumuplayer.com/fr/',
    ru: 'https://www.mumuplayer.com/ru/',
    es: 'https://www.mumuplayer.com/es/',
    vi: 'https://www.mumuplayer.com/vi/',
    id: 'https://www.mumuplayer.com/id/',
    th: 'https://www.mumuplayer.com/th/',
  };

  // const AdHostWhiteList = ['www.mumuplayer.com'];
  
  // if (AdHostWhiteList.indexOf(location.host) !== -1) {
  //   const url = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2608921919702191';
  //   getScript(url, { async: true, crossorigin: 'anonymous' });
  // }

  global.MUMUSDK = {

    mode: 'desktop',
    language: 'zh',
    excludeLanguage: [],
    onSelectLanguage: null,

    init(conf) {

      const mode = conf.mode || 'desktop';
      const lang = conf.language || 'zh';
      const excludeLanguage = conf.excludeLanguage
        ? (typeof conf.excludeLanguage === 'string' ? [conf.excludeLanguage] : conf.excludeLanguage)
        : [];
      const cb = conf.onSelectLanguage || null;

      const self = this;

      const getIndexLocation = function(lang) {
        return IndexLocationMap[lang] || IndexLocationMap.zh;
      }
      const getModeLocation = function(location) {
        if (self.mode !== 'mobile') return location;
        const match = location.match(/(.*\/\/.*?\/)(.*)/);
        if (!match) return location;
        return match[1] + 'm/' + match[2];
      }
      const defaultCb = function(lang) {
        const location = getModeLocation(getIndexLocation(lang));
        window.open(location);
      }
      this.mode = mode;
      this.language = lang;
      this.excludeLanguage = excludeLanguage;
      this.onSelectLanguage = typeof cb === 'function' ? cb : null;
      const _cb = cb || defaultCb;

      $(function() {
        $('body').find('.global_footer').remove();
        $('body').find('.global_header').remove();

        const matched = LangArr.find(({ key }) => self.language === key) || LangArr[0];
        self.language = matched.key;

        initiator[matched.key]({ mode: self.mode });
        const className = `is_${self.mode}`;
        $('body').find('.global_footer').addClass(className);
        $('body').find('.global_header').addClass(className);

        $('.nav_search').on('click', '.nav_search_box_more', function() {
          const type = $(this).data('type');
          const input = $('.nav_search_input').val();
          const url = `${getIndexLocation(self.language)}search/?data=${input}&className=${type}_search_all%20all`;
          window.location.href = url;
        });
        $('.nav_search_input').on('keypress', function(event) {
          if (event.keyCode == "13") { // 回车
            var input = $('.nav_search_input').val().trim();
            if (input.length > 0) {
              const url = `${getIndexLocation(self.language)}search/?data=${input}`;
              window.location.href = url;
            }
          }
        });

        // 修改页脚"游戏"部分地址
        console.log($('body').find('.global_footer .footer-games a'));
        $('body').find('.global_footer .footer-games a').attr('href', function(i, oldVal) {
          return getModeLocation(oldVal);
        });

        // 修改导航栏 logo 跳转地址
        $('body').find('.global_header .nav_center .logo').attr('href', getModeLocation(getIndexLocation(self.language)));

        // 修改导航栏跳转地址
        $('body').find('.global_header .jump_nav>li>a').attr('href', function(i, oldVal) {
          if (/business/.test(oldVal)) return oldVal;
          return getModeLocation(oldVal);
        });

        // 导航栏插入语言
        const restLang = LangArr
          .filter(({ key }) => excludeLanguage.every(lang => lang !== key))
          .filter(({ disabled }) => !disabled)
          .filter(({ key }) => key !== matched.key);
        const htmlLabel = $(`<a href="javascript:;">${matched.title}</a><i class="icon_arrow"></i>`);
        const htmlDropdown = $('<ul class="drop-down language">' + restLang
          .map(({ key, title }) => `<li><a href="javascript:;" title=${title} data-lang=${key}>${title}</a></li>`)
          .join('') + '</ul>');
        htmlDropdown.on('click', 'li', function() {
          const selected = $(this).find('a').data('lang');
          _cb(selected, matched.key);
        });
        $('.language_switching').html(htmlLabel);
        $('.language_switching').append(htmlDropdown);
        $('.language_switching').on('click', function() {
          $(this).toggleClass('on');
          $('.jump_nav').removeClass('on');
        });
        $('.jump_nav > li').on('click', function() {
          const on = $(this).hasClass('on');
          $('.jump_nav > li').removeClass('on');
          if (!on) $(this).addClass('on');
        });
        $('.m_btn_menu').on('click', function() {
          $('.jump_nav').toggleClass('on');
          $('.language_switching').removeClass('on');
        });
      });
    },

    changeLanguage(lang, cb) {
      if (typeof cb === 'function') this.onSelectLanguage = cb;
      this.init({
        language: lang,
        excludeLanguage: this.excludeLanguage,
        onSelectLanguage: this.onSelectLanguage,
        mode: this.mode
      });
    },

    changeMode(mode) {
      this.mode = mode || 'desktop';
      this.init({
        language: this.language,
        excludeLanguage: this.excludeLanguage,
        onSelectLanguage: this.onSelectLanguage,
        mode: this.mode,
      });
    }
  }
})(window);

