import header from './html/header.html';
import footer from './html/footer.html';

let NIE = null;

export default function(conf) {
  const mode = conf.mode || 'desktop';
  const $header = $($(header));
  const $footer = $($(footer));
  $('body').append($header);
  $('body').append($footer);

  const height = mode === 'mobile' ? '56px' : '72px';
  $('body').css('padding-top', height);

  function showCopyright() {
    $footer.find('#NIE-copyRight, .NIE-copyRight_m').remove();
    const html = mode === 'mobile'
      ? '<div class="NIE-copyRight_m"></div>'
      : '<div id="NIE-copyRight"></div>';
    $footer.find('.global_footer_box').append(html);
    NIE.util.copyRight();
  }

  if (!NIE) {
    const script = document.createElement('script');
    script.src="//comm.res.easebar.com/js/jquery(mixNIE).1.11.js";
    document.body.appendChild(script);
    script.onload = function () {
      NIE = window.nie;
      window.nie.config.copyRight.setWhite();
      showCopyright();
    };
  } else {
    showCopyright();
  }
}
