import header from './html/header.html';
import footer from './html/footer.html';
import {
  appendNewsList,
  searchGames,
  searchBlogs,
  searchSupport,
  updateGlobalSearch,
  clearGlobalSearch,
} from '../../utils';

export default function(conf) {
  const mode = conf.mode || 'desktop';
  const $header = $($(header));
  const $footer = $($(footer));
  $('body').append($header);
  $('body').append($footer);

  const height = mode === 'mobile' ? '56px' : '72px';
  $('body').css('padding-top', height);

  // 底部新闻
  appendNewsList(
    30620,
    $footer.find(".footer-ajax.news"),
  );
  // 底部常见问题
  appendNewsList(
    '36342,36343,36344,36345,36346,36347,36348,36349,36350',
    $footer.find(".footer-ajax.faq"),
  );

  const handleSearch = (() => {
    let timer = 0;
    let feed = 0;
    return () => {
      if (timer) window.clearTimeout(timer);
      feed += 1;
      timer = window.setTimeout(() => {
        var str = $('.nav_search_input').val().trim();

        if (!str) {
          clearGlobalSearch();
          $('.nav_search_box').slideUp(500);
          return;
        }
        const _feed = feed;
        Promise.all([
          searchGames(str, 'zh-TW'),
          searchBlogs(str, '36364,30620,36807,36808'),
          searchSupport(str, '36342,36343,36344,36345,36346,36347,36348,36349,36350'),
        ]).then(([games, blogs, faqs]) => {
          if (_feed !== feed) return;
          updateGlobalSearch({ games, blogs, faqs }, {
            'empty': `沒有<span>${str}</span>的結果，換個關鍵詞試試`,
            'empty-games': '沒有相關的<span>遊戲</span>，換個關鍵詞試試',
            'empty-blogs': '沒有相關的<span>資訊</span>，換個關鍵詞試試',
            'empty-faqs': '沒有相關的<span>常見問題</span>，換個關鍵詞試試',
            'games-title': '遊戲',
            'blogs-title': '資訊',
            'faqs-title': '常見問題',
            'see-all': '查看全部',
          });
          if (!$('.nav_search').hasClass('on')) return;
          $('.nav_search_box').slideDown(500);
        });
      }, 200);
    }
  })()

  // 搜索栏
  $('.nav_search_input').bind('input propertychange', handleSearch)
  // 搜索相关交互
  $('.nav_search_btn').click(function() {
    handleSearch();
    $('.nav_search').addClass('on');
    $('.nav_search_input').focus();
    $('.jump_nav').hide();
    const val = $('.nav_search_input').val();
    if (val) {
      $('.nav_search_box').slideDown(500);
    }
  });
  $('.nav_search_input').blur(function() {
    $('.nav_search').removeClass('on');
    $('.nav_search_box').slideUp(500);
    $('.jump_nav').show(1000);
  });
}
