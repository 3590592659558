import header from './html/header.html';
import footer from './html/footer.html';
import {
  appendNewsList,
  searchGames,
  searchBlogs,
  searchSupport,
  updateGlobalSearch,
  clearGlobalSearch,
} from '../../utils';

export default function(conf) {
  const mode = conf.mode || 'desktop';
  const $header = $($(header));
  const $footer = $($(footer));
  $('body').append($header);
  $('body').append($footer);

  const height = mode === 'mobile' ? '56px' : '72px';
  $('body').css('padding-top', height);

  // 底部新闻
  appendNewsList(
    34863,
    $footer.find(".footer-ajax.news"),
  );
  // 底部常见问题
  appendNewsList(
    '35505,35506,35507,35508,35509,35510,35511,35512,35513',
    $footer.find(".footer-ajax.faq"),
  );
  const handleSearch = (() => {
    let timer = 0;
    let feed = 0;
    return () => {
      if (timer) window.clearTimeout(timer);
      feed += 1;
      timer = window.setTimeout(() => {
        var str = $('.nav_search_input').val().trim();

        if (!str) {
          clearGlobalSearch();
          $('.nav_search_box').slideUp(500);
          return;
        }
        const _feed = feed;
        Promise.all([
          searchGames(str, 'es'),
          searchBlogs(str, '35123,34863,36811,36812'),
          searchSupport(str, '35505,35506,35507,35508,35509,35510,35511,35512,35513'),
        ]).then(([games, blogs, faqs]) => {
          if (_feed !== feed) return;
          updateGlobalSearch({ games, blogs, faqs }, {
            'empty': `No hay resultados para <span>${str}</span>, pruebe con otra palabra clave`,
            'empty-games': 'No hay <span>juego</span> relacionado, prueba con otra palabra clave',
            'empty-blogs': 'No hay <span>artículo</span> relacionado, prueba con otra palabra clave',
            'empty-faqs': 'No hay <span>preguntas frecuentes</span> relacionadas, prueba con otra palabra clave',
            'games-title': 'Juegos',
            'blogs-title': 'Blog',
            'faqs-title': 'Soporte',
            'see-all': 'Ver todo',
          });
          if (!$('.nav_search').hasClass('on')) return;
          $('.nav_search_box').slideDown(500);
        });
      }, 200);
    }
  })()

  // 搜索栏
  $('.nav_search_input').bind('input propertychange', handleSearch)
  // 搜索相关交互
  $('.nav_search_btn').click(function() {
    handleSearch();
    $('.nav_search').addClass('on');
    $('.nav_search_input').focus();
    $('.jump_nav').hide();
    const val = $('.nav_search_input').val();
    if (val) {
      $('.nav_search_box').slideDown(500);
    }
  });
  $('.nav_search_input').blur(function() {
    $('.nav_search').removeClass('on');
    $('.nav_search_box').slideUp(500);
    $('.jump_nav').show(1000);
  });
}
